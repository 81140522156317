import Link from 'next/link'
import useClickOutside from '@bww/beke-lib/useClickOutside'

import { motion } from 'framer-motion'

import styles from './NavSidebar.module.scss'

import { siteConfig } from '@bww/beke-lib/site-config'

const allNavItems = [
  ...siteConfig.navItems.left,
  ...siteConfig.navItems.right,
].reduce((acc, i) => {
  if (!i.hasOwnProperty('children') && !!i.href) return [...acc, i]
  if (i.children?.length > 0) return [...acc, ...i.children]
  return acc
}, [])

const navItemVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 30,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export default function NavSidebar({ visible, setVisible }) {
  const sidebarNode = useClickOutside(visible, () => {
    setVisible(!visible)
  })

  const style = {
    display: visible ? 'block' : 'none',
    pointerEvents: visible ? 'auto' : 'none',
  }

  return (
    <div className={styles.overlay} style={style}>
      <motion.div
        drag="x"
        dragElastic={false}
        dragConstraints={{ right: 0 }}
        onDragEnd={() => {
          setVisible(false)
        }}
        ref={sidebarNode}
        className={styles.sidebar}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: '-100%' },
        }}
        transition={'spring'}
        animate={visible ? 'visible' : 'hidden'}
      >
        <button
          className={styles.closeButton}
          onClick={() => setVisible(false)}
        >
          &times;
        </button>
        <motion.nav
          variants={{
            visible: {
              opacity: 1,
              x: 0,
              transition: { staggerChildren: 0.05, delayChildren: 0.3 },
            },
            hidden: {
              opacity: 0,
              x: '-100%',
              transition: { staggerChildren: 0.05, staggerDirection: -1 },
            },
          }}
          animate={visible ? 'visible' : 'hidden'}
        >
          <ul>
            {allNavItems.map(({ title, href }) => (
              <motion.li variants={navItemVariants} key={title}>
                <Link href={href}>{title}</Link>
              </motion.li>
            ))}
            <motion.li variants={navItemVariants}>
              <Link href="/poole-showroom">Poole Showroom</Link>
            </motion.li>
            <motion.li variants={navItemVariants}>
              <Link href="/contact">Contact us</Link>
            </motion.li>
            <motion.li variants={navItemVariants}>
              <a href={siteConfig.sisterCompany.url}>
                {siteConfig.sisterCompany.companyName}
              </a>
            </motion.li>
          </ul>
        </motion.nav>
      </motion.div>
    </div>
  )
}
