import { useEffect, useRef } from 'react'

export default function useClickOutside(state, onClickOutside) {
  const node = useRef()

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!node?.current) return
      if (node.current.contains(e.target)) return // inside click
      onClickOutside() // outside click
    }
    if (state) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [state, onClickOutside])

  return node
}
