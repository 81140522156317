import Preheader from './Preheader'
import Navbar from './Navbar'
import CTABar from './CtaBar'
import NavSidebar from './NavSidebar'

import styles from './Header.module.scss'

import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

export default function Header() {
  const router = useRouter()
  const [sidebarVisible, setSidebarVisible] = useState(false)

  useEffect(() => {
    if (router.isReady) setSidebarVisible(false)
  }, [router.asPath, router.isReady])

  return (
    <>
      <NavSidebar visible={sidebarVisible} setVisible={setSidebarVisible} />
      <header className={styles.header}>
        <Preheader />
        <Navbar setSidebarVisible={setSidebarVisible} />
        <CTABar />
      </header>
    </>
  )
}
