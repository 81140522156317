import Link from 'next/link'
import classnames from 'classnames'

import linkIcon from '@bww/beke-ui/icons/link.svg'
import styles from './Header.module.scss'

import { siteConfig } from '@bww/beke-lib/site-config'

export default function Preheader() {
  return (
    <div className={classnames('container', styles.preheader)}>
      <div className={styles.preheader__left}>
        <Link href="/covid-statement">
          <span className={styles.covidNotice__title}>COVID</span> precautions
          we&apos;re taking
        </Link>
      </div>
      <div className={styles.secondaryNav}>
        <Link href="/trade-contract">Trade &amp; Contract</Link>
        <a href={siteConfig.sisterCompany.url} className={styles.externalLink}>
          {siteConfig.sisterCompany.companyName}
          <svg width=".8em" height=".8em" viewBox="0 0 256 256">
            <use href={`${linkIcon.src}#icon`} />
          </svg>
        </a>
      </div>
    </div>
  )
}
