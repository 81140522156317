import styles from './Footer.module.scss'
import keLogoMain from '@/img/kitchen-elegance-logo-main.jpg'
import beLogoMain from '@/img/bathroom-elegance-logo-main.jpg'
import linkIcon from '@bww/beke-ui/icons/link.svg'

import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'

import { ConditionalWrapper } from '@bww/beke-ui/ConditionalWrapper'
import { siteConfig, isKE, isBE } from '@bww/beke-lib/site-config'
const { address } = siteConfig

export default function Footer() {
  return (
    <footer>
      <WholeHome />
      <div className={styles.footerWrapper}>
        <div className="container">
          <div className={styles.showroomInfo}>
            <div className={styles.address}>
              <h4>Showroom</h4>
              <span>{address.address1}</span>
              <br />
              {address.address2 && (
                <>
                  <span>{address.address2}</span>,{' '}
                </>
              )}
              <span>{address.locality}</span>
              <br />
              <span>Dorset</span>
              &ensp;
              <span>{address.postCode}</span>
            </div>
            <div className={styles.hours}>
              <div>Monday to Friday 9am – 5pm</div>
              <div>Saturday 9am – 4pm</div>
              <div>Sunday Closed</div>
            </div>
          </div>
          <div className={styles.contactDetails}>
            <div className={styles.telephone}>
              <strong>Telephone</strong>&ensp;
              <a href={`tel:${siteConfig.tel.replaceAll(' ', '')}`}>
                {siteConfig.tel}
              </a>
            </div>
            <div className={styles.contactOnline}>
              <Link href="/contact">Contact us online</Link>
            </div>
          </div>
        </div>
        <div className={classNames('container', styles.legalContainer)}>
          <div>
            <div>
              © {new Date().getFullYear()} {siteConfig.companyName} Limited. All
              rights reserved.
              <br />
              {siteConfig.companyName} and{' '}
              {siteConfig.sisterCompany.companyName} are registered trade marks.
            </div>
            <nav>
              <Link href="/privacy-policy.pdf">Privacy Policy</Link>
            </nav>
          </div>
          <div className={styles.credit}>
            Site by <a href="https://www.bluewaterweb.co.uk">Blue Water</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

const WholeHome = () => {
  return (
    <div className={styles.wholeHomeContainer}>
      <div className="h5">Interior design for your whole home</div>
      <div className={styles.logosContainer}>
        <ConditionalWrapper
          condition={isBE}
          wrapper={(children) => (
            <ExploreSite url={siteConfig.sisterCompany.url}>
              {children}
            </ExploreSite>
          )}
        >
          <Image
            src={keLogoMain}
            alt="Kitchen Elegance logo"
            width={290}
            height={45}
          />
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={isKE}
          wrapper={(children) => (
            <ExploreSite url={siteConfig.sisterCompany.url}>
              {children}
            </ExploreSite>
          )}
        >
          <Image
            src={beLogoMain}
            alt="Bathroom Elegance logo"
            width={290}
            height={45}
          />
        </ConditionalWrapper>
      </div>
    </div>
  )
}

const ExploreSite = ({ url, children }) => (
  <a href={url}>
    {children}
    <div>
      Explore <strong>{url.replace('https://www.', '')}</strong>{' '}
      <svg width=".8em" height=".8em" viewBox="0 0 256 256">
        <use href={`${linkIcon.src}#icon`} />
      </svg>
    </div>
  </a>
)
