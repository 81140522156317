import Link from 'next/link'
import styles from './CTABar.module.scss'
import { motion, useInView } from 'framer-motion'
import { useRef } from 'react'

import classNames from 'classnames'

import { siteConfig, isKE } from '@bww/beke-lib/site-config'

export default function CTABar() {
  const itemVariants = {
    default: {
      opacity: 1,
      y: 0,
      marginBottom: 0,
      transition: {
        type: 'spring',
        duration: 0.5,
        damping: 40,
        stiffness: 200,
      },
    },
    compact: {
      opacity: 0,
      y: -15,
      marginBottom: -15,
      transition: { type: 'spring', duration: 1, damping: 20, stiffness: 300 },
    },
  }

  const ref = useRef(null)
  const isInView = useInView(ref, {
    margin: '-70px',
  })

  const animationState = !ref.current || isInView ? 'default' : 'compact'

  return (
    <motion.div
      ref={ref}
      className={styles.ctaWrapper}
      initial="default"
      animate={animationState}
      variants={{
        default: {
          transition: {
            delayChildren: 2.5,
            staggerChildren: 0.08,
          },
        },
      }}
    >
      <div className={styles.ctaContainer}>
        <Link href="/" legacyBehavior passHref={true}>
          <motion.a
            className={styles.companyLogoIcon}
            variants={{
              default: { opacity: 0, y: '-25%' },
              compact: { opacity: 1, y: 0 },
            }}
            transition={{ type: 'spring', delay: 0.1, duration: 1 }}
          >
            <img
              src={siteConfig.logoIcon.src}
              alt={`${siteConfig.companyName} logo icon`}
              height={32}
              width={32}
            />
          </motion.a>
        </Link>
        <Link
          href="/poole-showroom"
          className={classNames(styles.ctaItem, styles.showroomItem)}
        >
          <motion.div className={styles.ctaItem__mc} variants={itemVariants}>
            see our {isKE ? 'kitchens' : 'bathrooms'}
          </motion.div>
          <div className={styles.ctaItem__title}>Poole showroom</div>
        </Link>
        <a
          href={`tel:${siteConfig.tel.replaceAll(' ', '')}`}
          className={styles.ctaItem}
        >
          <motion.div className={styles.ctaItem__mc} variants={itemVariants}>
            speak with us
          </motion.div>
          <div className={styles.ctaItem__title}>{siteConfig.tel}</div>
        </a>
        <Link href="/contact" className={styles.ctaItem}>
          <motion.div className={styles.ctaItem__mc} variants={itemVariants}>
            send a message
          </motion.div>
          <div className={styles.ctaItem__title}>Contact form</div>
        </Link>
      </div>
    </motion.div>
  )
}
