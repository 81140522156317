import Image from 'next/image'
import Link from 'next/link'
import DropdownNav from './DropdownNav'

import menuIcon from '@bww/beke-ui/icons/menu.svg'
import pinterestIcon from '@bww/beke-ui/icons/pinterest.svg'
import instagramIcon from '@bww/beke-ui/icons/instagram.svg'

import styles from './Navbar.module.scss'

import { siteConfig, isKE } from '@bww/beke-lib/site-config'
const hasSocial = siteConfig?.social?.length > 0

export default function Navbar({ setSidebarVisible }) {
  return (
    <div className={styles.navBar}>
      <div className={styles.navBar__left}>
        <button
          className={styles.navButton}
          onClick={() => setSidebarVisible(true)}
        >
          <svg width={22} height={22} viewBox="0 0 256 256">
            <use href={`${menuIcon.src}#icon`} />
          </svg>
          Menu
        </button>
        <ul className={styles.navGroup}>
          {siteConfig.navItems.left.map(({ title, href }) => (
            <li key={href}>
              <Link href={href}>{title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.logoWrapper}>
        <Link href="/">
          <Image
            src={siteConfig.logo.src}
            alt={`${siteConfig.companyName} logo`}
            height={42}
            width={271}
            priority
          />
        </Link>
      </div>
      <div className={styles.navBar__right}>
        <ul className={styles.navGroup}>
          {siteConfig.navItems.right.map(({ title, href, children }) => (
            <li key={title}>
              {children ? (
                <DropdownNav
                  title={title}
                  navItems={children.map((i) => (
                    <Link href={i.href} key={i.href}>
                      {i.title}
                    </Link>
                  ))}
                />
              ) : (
                <Link href={href}>{title}</Link>
              )}
            </li>
          ))}
        </ul>
        {hasSocial && <Social sites={siteConfig.social} />}
      </div>
    </div>
  )
}

const Social = ({ sites }) => {
  const icons = {
    instagram: instagramIcon,
    pinterest: pinterestIcon,
  }
  return (
    <div className={styles.social}>
      {sites.map(({ site, url }) => (
        <a
          key={site}
          href={url}
          aria-label={`${siteConfig.companyName} on ${site}`}
        >
          <svg width={16} height={16} viewBox="0 0 256 256">
            <use href={`${icons[site].src}#icon`} />
          </svg>
        </a>
      ))}
    </div>
  )
}
