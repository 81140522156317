import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import 'the-new-css-reset/css/reset.css'
import Layout from '@/components/layout'
import '@bww/beke-ui/styles/globals.scss'

import * as gtag from '@bww/beke-lib/gtag'

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcnF3MjAAAAAEUxILoy4joc9Uem2IuSf6danvRO"
      scriptProps={{ defer: true }}
    >
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script id="ga4">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </GoogleReCaptchaProvider>
  )
}
