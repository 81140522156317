import styles from './DropdownNav.module.scss'
import { AnimatePresence, motion } from 'framer-motion'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import useOutsideClick from '@bww/beke-lib/useClickOutside'

import classNames from 'classnames'

const dropdownVariants = {
  hidden: { y: 8, opacity: 0 },
  show: { y: 0, opacity: 1 },
  exit: { y: -4, opacity: 0 },
}

// TODO hide on scroll/sticky

const DropdownNav = ({ title, navItems }) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const node = useOutsideClick(open, () => setOpen(false))

  useEffect(() => {
    if (router.isReady) setOpen(false)
  }, [router.asPath, router.isReady])

  useEffect(() => {
    const escapeKeyHandler = (e) => {
      if (open && e.key === 'Escape') setOpen(false)
    }
    if (open) window.addEventListener('keydown', escapeKeyHandler)
    return () => {
      window.removeEventListener('keydown', escapeKeyHandler)
    }
  }, [open])

  return (
    <div ref={node} className={styles.wrapper}>
      <button
        className={classNames(styles.button, { [styles.active]: open })}
        onClick={toggle}
      >
        {title}
        <div className={styles.iconWrapper}>
          <svg
            width="1.2em"
            height="1.2em"
            viewBox="0 0 300 300"
            fill="currentColor"
          >
            <circle cx="55" cy="150" r="36" />
            <circle cx="150" cy="150" r="36" />
            <circle cx="245" cy="150" r="36" />
          </svg>
        </div>
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            className={styles.dropdownList}
            variants={dropdownVariants}
            initial="hidden"
            animate={open ? 'show' : 'hidden'}
            exit="exit"
            transition={{ duration: 0.2 }}
          >
            {navItems && <DropdownNavList navItems={navItems} />}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default DropdownNav

const navItemVariants = {
  hidden: { y: 8 },
  show: { y: 0 },
}

const DropdownNavList = ({ navItems }) => {
  return (
    <motion.ul
      variants={{
        show: { transition: { staggerChildren: 0.03 } },
      }}
    >
      {navItems.map((item, i) => (
        <motion.li key={i} variants={navItemVariants}>
          {item}
        </motion.li>
      ))}
    </motion.ul>
  )
}
