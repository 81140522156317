import keLogoMain from '@/img/kitchen-elegance-logo-main.jpg'
import beLogoMain from '@/img/bathroom-elegance-logo-main.jpg'

import keLogoIcon from '@/img/ke-logo-icon.png'
import beLogoIcon from '@/img/be-logo-icon.png'

export const sanityConfig = {
  projectId: 'ly18yecr',
  dataset: 'production',
  useCdn: false,
  apiVersion: '2023-05-16',
}

export const isKE = process.env.NEXT_PUBLIC_COMPANY === 'KE'
export const isBE = process.env.NEXT_PUBLIC_COMPANY === 'BE'

export const siteConfigs = {
  BE: {
    companyName: 'Bathroom Elegance',
    company: 'BE',
    tel: '01202 675992',
    url: 'https://www.bathroomelegance.uk.com',
    logo: beLogoMain,
    logoIcon: beLogoIcon,
    address: {
      address1: 'Unit 4, Nuffield Trade Centre',
      address2: '11-17 Nuffield Road',
      address3: 'Nuffield Trade Centre',
      locality: 'Poole',
      postCode: 'BH17 0UF',
    },
    foundedYear: 2007,
    googlePlaceUrl: 'https://goo.gl/maps/gUonKutZkhhatx837',
    navItems: {
      left: [
        // { title: 'Projects', href: '/projects' },
        {
          title: 'Bathroom Inspiration',
          href: '/inspiration',
        },
        { title: 'Our Brands', href: '/brands' },
      ],
      right: [
        {
          title: 'About us',
          children: [
            { title: 'Our Process', href: '/about/our-process' },
            { title: 'Our Story', href: '/about/our-story' },
            { title: 'Testimonials', href: '/client-testimonials' },
          ],
        },
        { title: 'FAQs', href: '/faqs' },
      ],
    },
    social: [
      {
        site: 'instagram',
        url: 'https://www.instagram.com/bathroomelegancepoole/',
      },
    ],
  },
  KE: {
    companyName: 'Kitchen Elegance',
    company: 'KE',
    tel: '01202 740630',
    url: 'https://www.kitchenelegance.co.uk',
    logo: keLogoMain,
    logoIcon: keLogoIcon,
    address: {
      address1: 'Ashley Cross',
      address2: '19-21 Bournemouth Road',
      locality: 'Poole',
      postCode: 'BH14 0EF',
    },
    foundedYear: 1986,
    googlePlaceUrl: 'https://goo.gl/maps/SKnp5ogsNqJgZonA8',
    navItems: {
      left: [
        { title: 'Kitchens', href: '/projects' },
        { title: 'Other Rooms', href: '/other-rooms' },
        { title: 'Inspiration', href: '/inspiration' },
      ],
      right: [
        {
          title: 'About us',
          children: [
            { title: 'Our Process', href: '/about/our-process' },
            { title: 'Our Brands', href: '/brands' },
            { title: 'Our Story', href: '/about/our-story' },
            { title: 'Testimonials', href: '/client-testimonials' },
          ],
        },
        { title: 'FAQs', href: '/faqs' },
      ],
    },
    social: [
      {
        site: 'instagram',
        url: 'https://www.instagram.com/kitchenelegancepoole/',
      },
      {
        site: 'pinterest',
        url: 'https://www.pinterest.co.uk/kitchenelegancepoole/',
      },
    ],
  },
}

const siteConfig = siteConfigs[process.env.NEXT_PUBLIC_COMPANY]
siteConfig.sisterCompany =
  process.env.NEXT_PUBLIC_COMPANY === 'KE' ? siteConfigs.BE : siteConfigs.KE

export { siteConfig }
